<template>
  <AppNav v-if="appView">채팅 패스 Beta</AppNav>
  <div class="promotion" :style="{ 'padding-top': appPadding(), 'padding-bottom': appBottom() }">
    <div class="top-banner">
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_top.png" />
    </div>

    <div class="promotion-banner">
      <div :style="{ display: 'none' }">
        <CouponBox />
      </div>

      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_point01.png" />
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_point02.png" />
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_point03.png" />
    </div>
    <div class="promitions-items">
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_common_sec01.png" />
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_sec01.png" />
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_sec02.png" />
      <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_sec03.png" />
      <!-- <img src="//res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_sec04.png" /> -->
    </div>

    <PageCRMSurveyVue />

    <div
      v-if="purchase.isPurchaseClicked"
      class="common-modal common-modal--dimmed"
      id="modalCertificateClose"
      style="display: block"
    >
      <div class="common-modal__modal modal--purchase" v-click-outside="purchase.closePurchaseModal">
        <div class="common-modal__content">
          <div class="common-modal__description" v-html="purchase.purchaseErrorMessage"></div>
          <div class="common-modal__description--small">
            {{ purchase.purchaseErrorSubMessage }}
          </div>
        </div>

        <div class="common-modal__buttonWrap" v-if="purchase.puchaseErrorType === 'level'">
          <button type="button" class="common-modal__button" @click="purchase.closePurchaseModal">취소</button>
          <button type="button" class="common-modal__button" @click="purchase.goPurchasePage">확인</button>
        </div>
        <div class="common-modal__buttonWrap" v-if="purchase.puchaseErrorType === 'hasTicket'">
          <button type="button" class="common-modal__button" @click="purchase.closePurchaseModal">확인</button>
        </div>
      </div>
    </div>

    <FooterInfo v-if="promotion.isLoading" :promotionData="promotion.promotionData" />

    <section class="challenge-cta" id="challengeCta">
      <div class="challenge-cta__wrap" :class="{ sheetOn: modal.isMobileBottomSheetOpen }">
        <div class="wrapper">
          <!--이 버튼은 시트 올라오기 전 버튼-->
          <button
            v-if="modal.isMobileBottomSheetOpen"
            @click="purchase.purchasePlan()"
            type="button"
            class="bottom_tab"
          >
            혜택 확인하기
          </button>
          <div class="btnwrap" v-if="!modal.isMobileBottomSheetOpen">
            <!-- pc 버전 -->
            <!-- <button
              type="button"
              class="cta__button pc-layer"
              v-scroll-to="{
                el: '#tutoringContent',
                duration: 200,
                easing: 'linear',
                offset: -exceptBnrScroll,
                x: false,
                y: true,
                onStart: () => {
                  // trackingUserAction('chobo_package_footer');
                },
              }"
            >
              수강권 둘러보기
            </button> -->
            <button @click="goToApply" type="button" class="fixed__button">수강권 둘러보기</button>
          </div>
        </div>
      </div>
    </section>

    <!-- <article class="sheet" :class="{ 'is-show': modal.isMobileBottomSheetOpen }" id="bottomUpSheet">
      <div class="sheet__wrap">
        <div class="btn" @click="modal.toggleBottomSheetTab()"></div>
        <Pr90DaysOptions
          class="textarea__option"
          @update="purchase.updateOptionIdx"
          :promotionData="promotion.promotionData"
          :choicedOptionIdx="purchase.choicedOptionIdx"
          :choicedOptionData="purchase.choicedOptionData"
          :isRecommendIdx="purchase.isRecommendIdx"
          :isInstallment="false"
          :isChat="true"
        />
      </div>
      <div class="dim" @click="modal.toggleBottomSheetTab()"></div>
    </article> -->
  </div>
</template>
<script setup>
import { computed, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import inappTools from '@/common/inappTools';
import api from '@/common/api';
import tools from '@/common/tools';
import AppNav from '@/components/AppNav.vue';
import { storage, native } from '@/tools';
// import Pr90DaysOptions from '../component/Pr90DaysOptions.vue';
import PageCRMSurveyVue from '@/components/PageCRMSurvey/PageCRMSurvey.vue';
import FooterInfo from './component/FooterInfo.vue';
// import CouponBox from '../../pages/promotion/component/CouponBox.vue';
import CouponBox from '../component/CouponBox.vue';

const appPadding = () => {
  if (inappTools.isInApp()) {
    return '45px';
  }
  return '0px';
};
const appBottom = () => {
  if (inappTools.isInApp()) {
    return '100px';
  }
  return '0px';
};
const goHome = () => {
  if (!inappTools.isInApp()) {
    window.location.href = `${window.location.protocol}/v2/classApply`;
  }
  if (inappTools.isInApp()) {
    storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain');
    native.close();
  }
};
// },
// };
const modal = reactive({
  isCertificate: false,
  isPurchaseClicked: false,
  isMobileBottomSheetOpen: false,
  openSample: () => {
    modal.isCertificate = !modal.isCertificate;
  },
  openPurchaseModal: () => {
    modal.isPurchaseClicked = true;
  },
  toggleBottomSheetTab: () => {
    modal.isMobileBottomSheetOpen = !modal.isMobileBottomSheetOpen;
  },
});

const purchase = reactive({
  choicedOptionIdx: 9161,
  choicedOptionData: {},
  isRecommendIdx: 0,
  isPurchaseClicked: false,
  purchaseErrorMessage: '',
  purchaseErrorSubMessage: '',
  purchaseErrorType: '',
  purchasePlan: () => {
    const copyLoggedInUser = storage.session.get('loggedInUser');
    if (copyLoggedInUser) {
      // this.trackingUserAction('chobo_package_footer_payment');
      purchase.goPurchasePage();
    } else {
      purchase.pushToLogin();
    }
  },

  pushToLogin: () => {
    if (!tools.isLoggedIn()) {
      window.location.href = '/home/login';
    }
  },
  updateOptionIdx: (Id) => {
    purchase.choicedOptionIdx = Id;
    purchase.choicedOptionData = {
      // eslint-disable-next-line
      ...promotion.promotionData.plans.filter((plan) => plan.cp_idx === purchase.choicedOptionIdx)[0],
    };
    console.log(purchase.choicedOptionIdx);
  },
  openPurchaseModal: () => {
    purchase.isPurchaseClicked = true;
  },
  closePurchaseModal: () => {
    purchase.isPurchaseClicked = false;
    if (inappTools.isInApp()) {
      native.close();
    } else {
      this.$router.push('/');
    }
  },
  goPurchasePage: () => {
    // eslint-disable-next-line
    const selectedPlan = promotion.promotionData.plans.filter((plan) => plan.cp_idx === purchase.choicedOptionIdx)[0];
    storage.session.set('goodsForOrder', selectedPlan);
    if (inappTools.isInApp()) {
      const url = `https://${document.location.host}/app/home/order`;
      window.location.href = url;
    } else {
      const url = `https://${document.location.host}/home/order?&ptype=Kr&courseIdx=undefined`;
      window.location.href = url;
    }
  },
});

const coupon = reactive({
  isCouponClicked: false,
  couponData: { plans: [{ cp_idx: 0 }] },
  showCouponModal: () => {
    coupon.isCouponClicked = !coupon.isCouponClicked;
    if (coupon.isCouponClicked) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.pageYOffset}`;
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  },
  //   getCouponData: async () => {
  //     coupon.couponData = await api.get('/app/etc/promotion/365low').then((response) => response.data);

  //     coupon.couponData.coupon.data.coupons.forEach((couponItem) => {
  //       if (couponItem.hasCoupon) {
  //         coupon.hasCouponIdx.push(couponItem.idx);
  //       }
  //     });
  //     if (coupon.hasCouponIdx.length >= 4) {
  //       coupon.isDownload = !coupon.isDownload;
  //     }
  //     console.log('couponData');
  //     console.log(coupon.couponData);
  //   },
});

const promotion = reactive({
  promotionData: [],
  isLoading: false,
  // /app/etc/promotion?channel=8&promotionCode=chattingTest&key=
  getData: async () => {
    promotion.promotionData = await api
      .get('/app/etc/promotion', {
        channel: 8,
        promotionCode: 'chattingPass',
      })
      .then((response) => response.data);
    const RecommendPlan = promotion.promotionData.plans.filter((data) => data.isRecommend);
    const RecommendIndex = promotion.promotionData.plans.findIndex((data) => data.isRecommend);
    purchase.isRecommendIdx = RecommendIndex;
    if (RecommendPlan.length === 0) {
      purchase.choicedOptionIdx = promotion.promotionData.plans[0].cp_idx;
      purchase.choicedOptionData = { ...promotion.promotionData.plans[0] };
    } else {
      purchase.choicedOptionIdx = RecommendPlan[0].cp_idx;
      purchase.choicedOptionData = { ...RecommendPlan[0] };
    }

    promotion.isLoading = true;
  },
  setTrackingUserAction: () => {
    const device = inappTools.isInApp() ? 'APP' : 'WEB';
    api.post('/app/users/setTracking', { event_name: 'detail', page_name: 'chattingpass', device_type: `${device}` });
  },
});

onMounted(() => {
  if (localStorage.getItem('key')) {
    promotion.setTrackingUserAction();
  }
  promotion.getData();
  goHome();
  //   coupon.getCouponData();
});

const appView = computed(() => inappTools.isInApp());
const router = useRouter();

function goToApply() {
  router.push({ name: 'planAllPass', params: { prevPage: 'chattingPass' } });
}
</script>
<style lang="scss" scoped src="../../../assets/newPromotions.scss"></style>
<style lang="scss" scoped src="../../../assets/ChatPass.scss"></style>
