import { render } from "./FooterInfo.vue?vue&type=template&id=092f85ee&scoped=true"
import script from "./FooterInfo.vue?vue&type=script&setup=true&lang=js"
export * from "./FooterInfo.vue?vue&type=script&setup=true&lang=js"

import "../../../../assets/ChatPass.scss?vue&type=style&index=0&id=092f85ee&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-092f85ee"
/* hot reload */
if (module.hot) {
  script.__hmrId = "092f85ee"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('092f85ee', script)) {
    api.reload('092f85ee', script)
  }
  
  module.hot.accept("./FooterInfo.vue?vue&type=template&id=092f85ee&scoped=true", () => {
    api.rerender('092f85ee', render)
  })

}

script.__file = "src/page/promotion/ChatPass/component/FooterInfo.vue"

export default script