<template>
  <div class="footer-info">
    <div class="footer__wrap">
      <ul>
        <template v-for="(item, index) in footer.planGuide" :key="index">
          <li class="footer__item" @click="footer.toggleInfo(index)">
            <div class="footer__item__box">
              <p :class="{ active: item.isShow }">{{ item.title }}</p>
              <svg
                v-bind:class="{ 'flip-vertical': item.isShow }"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12 7L8 11L4 7"
                  stroke="#121214"
                  stroke-opacity="0.6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
          <div class="footer__info" v-if="item.isShow">
            <ul>
              <li v-for="(subItem, subIndex) in item.contents" :key="subIndex">
                {{ subItem.text }}
              </li>
            </ul>
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps, onMounted } from 'vue';

const props = defineProps({
  promotionData: Object,
});

const footer = reactive({
  planGuide: [],
  toggleInfo: (index) => {
    footer.planGuide[index].isShow = !footer.planGuide[index].isShow;
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  },
});

onMounted(() => {
  footer.planGuide = props.promotionData.planGuide;
  for (let i = 0; i < footer.planGuide.length; i += 1) {
    footer.planGuide[i].isShow = false;
  }
});
</script>
<style scoped lang="scss" src="../../../../assets/ChatPass.scss"></style>
